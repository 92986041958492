import { Component } from '@angular/core';

@Component({
	selector: "header-component",
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})

export class HeaderComponent {
	getAppUri: string = "https://itunes.apple.com/us/app/uc-units-converter/id1368701985?ls=1&mt=8";
	mailToAddress: string = "units-converter@itcarst.com";
	mailToSubject: string = "Units Converter Feedback";

	getAPP() {
		window.open(this.getAppUri);
	}
}